html, body, #root, .App, .height100 {
	height: 100%;
}

body {
	background-color: #383838;
}

.logoHeader {
	height: 32px;
	margin: 16px;
	text-align: center;
}

.logoutBtnHeader {
	float: right;
}

.form-border {
	border: 3px solid #f1f1f1;
	padding: 10px;
	border-radius: 10px;
}

.texture-row {
	min-height: 125px;
}

.texture-row .ant-form-item {
	margin-bottom: 0;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
	margin-left: 20px;
}

